<template>
  <div class="app-container">
    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="进度状态" prop="findType">
        <el-select v-model="queryParams.findType" size="mini" @change="getList">
          <el-option v-for="item in findTypeList" :key="item.value" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态筛选" prop="status">
        <el-select v-model="queryParams.status" size="mini" :clearable="true">
          <el-option v-for="(item,index) in orderStatusList" :key="index" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="订单编号" prop="orderNo">
        <el-input v-model="queryParams.orderNo" placeholder="请输入订单编号" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="机会ID" prop="opporId">
        <el-input v-model="queryParams.opporId" placeholder="请输入机会ID" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="商品名称" prop="goodsName">
        <el-input v-model="queryParams.goodsName" placeholder="请输入商品名称" clearable size="mini"/>
      </el-form-item>

      <el-form-item label="收款账号" prop="payAccount">
        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">
          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"
                     :label="item.dictLabel"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="支付时间" prop="payTimeArr">
        <el-date-picker
            v-model="payTimeArr"
            clearable
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-button type="success" size="mini" @click="showDetail">添加</el-button>
    </el-row>

    <el-table max-height="600" v-loading="loading" :data="repairList">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="订单编号" align="center" prop="orderNo" width="250"/>
      <el-table-column label="机会ID" align="center" prop="opporId" width="100"/>
      <el-table-column label="商品名称" align="center" prop="goodsName" width="190"/>
      <el-table-column label="原价" align="center" prop="price" width="100">
        <template v-slot="scope">
          {{ numberformat(scope.row.price / 100) }}
        </template>
      </el-table-column>
      <el-table-column label="支付金额" align="center" prop="payPrice" width="100">
        <template v-slot="scope">
          {{ numberformat(scope.row.payPrice / 100) }}
        </template>
      </el-table-column>
      <el-table-column label="归属学习顾问" align="center" prop="adviserId" width="100">
        <template v-slot="scope">
          <el-tag effect="plain" :disable-transitions="true" v-for="item in accountList" :key="item.id"
                  v-if="scope.row.adviserId === item.id">
            {{ item.nickName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="支付时间" align="center" prop="payTime" type="date" width="100"/>
      <el-table-column label="状态" align="center" prop="status" width="120">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" v-for="item in orderStatusList" :key="item.value" :type="item.type"
                  effect="dark"
                  v-if="scope.row.status === item.value">
            {{ item.label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="提交人" align="center" prop="submitId" width="100">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" effect="dark" v-for="item in accountList" :key="item.id"
                  v-if="scope.row.submitId === item.id">
            {{ item.nickName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="缴费方式" align="center" prop="payType">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" v-for="item in collectionWayList" :key="item.id"
                  v-if="scope.row.payType == item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单渠道" align="center" prop="orderChannel">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" type="warning" v-for="item in orderChannelList" :key="item.id"
                  v-if="scope.row.orderChannel == item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="收款账号" align="center" prop="payAccount">
        <template v-slot="scope">
          <el-tag :disable-transitions="true" type="success" v-for="item in payAccountArr" :key="item.id"
                  v-if="scope.row.payAccount == item.dictValue">
            {{ item.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单创建时间" align="center" prop="orderCreateTime" width="150" :sortable="true"/>
      <el-table-column label="操作" fixed="right" width="100" align="center" class-name="small-padding">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="repairInfo(scope.row.id)">
            查看详情
          </el-button>
          <el-button type="danger" size="mini"
                     @click="deleteOrderRepair(scope.row.id)"
                     v-if="scope.row.status === 0">
            删除
          </el-button>
          <el-tooltip content="审核可进行驳回,通过.驳回可以让学习顾问重新修改" placement="left"
                      v-if="(scope.row.status === 1 || scope.row.status === 2) && queryParams.findType !== 0">
            <el-button type="success" size="mini" @click="examineRepair(scope.row.id)">
              审核
            </el-button>
          </el-tooltip>
          <el-tooltip content="撤回后,订单可进行修改重新提交" placement="left"
                      v-if="scope.row.status === 1 || scope.row.status === 2">
            <el-button type="warning" size="mini" @click="backRepair(scope.row.id)">
              撤回
            </el-button>
          </el-tooltip>
          <el-tooltip content="订单在未提交前可进行修改" placement="left" v-if="scope.row.status === 0">
            <el-button type="error" size="mini" @click="updateRepair(scope.row.id)">
              修改
            </el-button>
          </el-tooltip>
          <el-tooltip content="提交后,订单将进入审批环节" placement="left" v-if="scope.row.status === 0">
            <el-button type="primary" size="mini" @click="submitRepair(scope.row.id)">
              提交
            </el-button>
          </el-tooltip>

        </template>
      </el-table-column>
    </el-table>

    <pagination
        :v-show="queryParams.total>10"
        :total="queryParams.total"
        :page.sync="queryParams.current"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <OrderRepairDetail :pond-id="pondId" @getList="getList" :dialogValue.sync="repairShow" :repair-id="repairId" v-if="repairShow"
                       :repairStatus.sync="repairStatus"></OrderRepairDetail>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import * as repairApi from "@/api/crm/orderRepair"
import OrderRepairDetail from "@/components/order/OrderRepairDetail.vue"
import {getUserId} from "@/utils/auth";
import * as  accountApi from "@/api/system/account";
import * as dectApi from '@/api/system/dictionary'

export default {
  name: "OrderRepair",
  components: {
    OrderRepairDetail
  },
  props: {
      pondId:{
          type:Number,
          default:1
      }
  },
  data() {
    return {
      withdraw: false,
      repairStatus: 0,
      repairId: 0,
      repairShow: false,
      payTimeArr: [],
      queryParams: {
        orderNo: null,
        goodsName: null,
        findType: 0,
        total: 0,
        current: 1,
        size: 10,
        status: null,
        startDateTime: null,
        endDateTime: null,
      },
      repairList: [],
      loading: false,
      adviserList: [],
      accountList: [],
      possessList: [],
      searchRules: {
        opporId: [
          {min: 1, max: 9, message: '请输入正确的机会ID', trigger: 'blur'}
        ]
      },
      orderChannelList: [],
      orderStatusList: [
        {value: 0, label: '未提交/驳回', type: 'danger'},
        {value: 1, label: '一审审批中', type: 'warning'},
        {value: 2, label: '财务核对中', type: 'warning'},
        {value: 9, label: '完成(未录入)', type: 'success'},
        {value: 10, label: '完成(已录入)', type: 'success'},
      ],
      collectionWayList: [],
      findTypeList: [
        {value: 0, label: "我的录单"},
        {value: 1, label: "审批录单"},
        {value: 2, label: "审核记录"},
        {value: 3, label: "全部"},
      ],
      payAccountArr: [],
    };
  },
  created() {
    this.init();
    this.getAdviserList()
    this.getPossessList()
    this.getAccountList()
    dectApi.list({dictParent: 14}).then(resp => {
      if (resp.success) {
        this.payAccountArr = resp.data
      } else this.$message.error(resp.msg)
    })
  },
  computed: {
    userId: function () {
      return getUserId()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 初始化数据
    init() {
      dectApi.list({dictParent: 14}).then(resp => {
        if (resp.success) {
          this.payAccountArr = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 15}).then(resp => {
        if (resp.success) {
          this.collectionWayList = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 16}).then(resp => {
        if (resp.success) {
          this.orderChannelList = resp.data
        } else this.$message.error(resp.msg)
      })
    },
    repairInfo(repairId) {
      this.repairId = repairId
      this.repairStatus = 9;
      this.repairShow = true
    },
    /**
     * 修改录单
     */
    updateRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 1;
      this.repairShow = true
    },
    /**
     * 审核
     */
    examineRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 2;
      this.repairShow = true
    },
    /**
     * 提交
     */
    submitRepair(row) {
      repairApi.submitRepairId(row).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 撤回
     */
    backRepair(repairId) {
      repairApi.backRepair(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 新增
    showDetail() {
      this.repairId = 0
      this.repairStatus = 0
      this.repairShow = true
    },
    //金额格式化
    numberformat(number) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
          prec = !isFinite(+2) ? 0 : Math.abs(2),
          s = '',
          toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.floor(n * k) / k;
          };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join('.');
    },
    /**
     * 获取唯一值配置
     */
    getPossessList() {
      api.possessList().then(res => {
        this.possessList = res.data
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    getAccountList: function () {
      accountApi.list().then(res => {
        console.log(res)
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true
      if (this.payTimeArr && this.payTimeArr[0]) {
        this.queryParams.startDateTime = this.payTimeArr[0]
        this.queryParams.endDateTime = this.payTimeArr[1]
      } else {
        this.queryParams.startDateTime = null
        this.queryParams.endDateTime = null
      }
      this.queryParams['submitId'] = this.submitId
      repairApi.listPage(this.queryParams).then(resp => {
        if (resp.success) {
          this.repairList = resp.data.records
          this.queryParams.total = resp.data.total
          this.queryParams.size = resp.data.size
          this.queryParams.current = resp.data.current
          this.loading = false
        } else {
          this.$message.error(resp.msg)
          this.queryParams.findType = 0
          this.getList()
          this.loading = false
        }

      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.payTimeArr = [];
      this.queryParams.startDateTime = null;
      this.queryParams.endDateTime = null;
      this.handleQuery();
    },
    deleteOrderRepair(orderRepairId) {
      repairApi.deleteOrderRepair(orderRepairId).then(res => {
        if (res.success) {
          this.$message.success(`删除成功!`);
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
.small-padding button {
  margin: 3px;
}
</style>
